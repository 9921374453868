import React, { useState } from 'react'
import Modal from 'react-modal'
import { Link } from "react-router-dom";

// import ReactDOM from 'react-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import CardIcons from './CardIcons';


export default function RestaurantCard(props) {

  const [modalIsOpen, setModalIsOpen] = useState(false)

  function openModal() {
    setModalIsOpen(true)
  }

  function closeModal() {
    setModalIsOpen(false)
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    }
  };

  const changeTodaySubHeader = (dayValue) => {
    const today = new Date().getDay();

    switch (dayValue) {
      case today:
        return "Today";
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
      default:
        return "Sunday";
    }

  }



  return (
    <div className="home-card__container">
      <div className="home-card__grid-template">
        <div className="home-card__quick-info--mobile-top-row">
          <div className="home-card__quick-info--name-hood">
            <div className="home-card__title">{props.name}</div>
            <div className="home-card__quick-info-row">
              <div className="home-card__quick-info-row--hood">{props.neighborhood}</div>
              <div>{props.hhHours.length > 1 ? "HH: " + props.hhHours : "No HH today"}</div>
            </div>
          </div>
          <CardIcons specialType={props.specialType} />
        </div>
        <div className="home-card__quick-info-btn-container">

          <Link to={"/" + props.slug}>
            <button className="btn home-card__quick-look-btn">Full Info</button>
          </Link>
          <button className="btn home-card__quick-look-btn" onClick={openModal}>Quick Look</button>
          <Modal
            isOpen={modalIsOpen}
            style={customStyles}
          >
            <div className="modal-container">

              <div className="quick-info-modal__main-header">{props.name}</div>
              <div className="quick-info-modal__main-subheader">{changeTodaySubHeader(props.today)}'s Quick Info</div>
              <div className="quick-info-modal__two-columns">
                <div className="quick-info-modal__left-column">
                  <div className="quick-info-modal__header-container">

                    <h2 className="quick-info-modal__h2">Happy Hour</h2>
                    <h3>{(props.hhHours.length > 1) ? (props.hhHours) : "No HH Today"}</h3>
                  </div>
                  <div className="quick-info-modal__quick-info-container">
                    {props.hhDrinks &&
                      <ul>
                        {props.hhDrinks.map(drink => (
                          <li key={drink}>{drink}</li>
                        ))}
                      </ul>
                    }
                    {props.hhFood &&
                      <ul>
                        {props.hhFood.map(food => (
                          <li key={food}>{food}</li>
                        ))}
                      </ul>
                    }
                  </div>
                </div>
                <div>
                  <div className="quick-info-modal__header-container">
                    <h2 className="quick-info-modal__h2">Specials</h2>
                    <h3>{(props.specialName.length > 1) ? (props.specialName) : ("No specials today")}</h3>
                  </div>
                  <div className="quick-info-modal__quick-info-container">
                    {props.specialOptions &&
                      <ul>
                        {props.specialOptions.map(special => (
                          <li key={special}>{special}</li>
                        ))}
                      </ul>
                    }
                  </div>
                </div>
              </div>
              <FontAwesomeIcon
                icon={faTimesCircle}
                onClick={closeModal}
                className="quick-info-modal__btn"
              />


            </div>
          </Modal>
        </div>


      </div>
    </div>
  )
}
