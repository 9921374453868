import React, { useState, useEffect } from "react";

import API from "../../utils/API"

import RestaurantCard from './restaurant-card/RestaurantCard'
import LeftColumnFilters from "./left-column-filter/LeftColumnFilters";

function FilterableData(props) {
  const [todaysRestaurants, setTodaysRestaurants] = useState(undefined)
  const [allRestaurants, setAllRestaurants] = useState(undefined)
  const [todaysSpecials, setTodaysSpecials] = useState(undefined)

  useEffect(() => {
    getAPI()
  }, [])

  useEffect(() => {
    if (allRestaurants !== undefined) {
      let filteredByDay = filterByDay(props.todayValue)
      let filteredByHood = filterByNeighborhood(filteredByDay)
      setTodaysRestaurants(filteredByHood)
    }
    // console.log(allRestaurants)
  }, [allRestaurants, props.neighborhoodValue, props.specialsValue, props.todayValue])

  useEffect(() => {
    if (todaysRestaurants !== undefined) {
      console.log("======== todaysRestaurants")
      console.log(todaysRestaurants)
      props.specialOptions(getSpecialsArray(todaysRestaurants))
    }
  }, [todaysRestaurants])

  const getAPI = () => {
    API.getRestaurants()
      .then(res => {
        setAllRestaurants(res.data)
      })
      .catch(err => console.log(err))
  }

  const filterByDay = (today) => {

    let todaysInfo = []

    for (let i = 0; i < allRestaurants.length; i++) {
      let storeInfo = {
        "id": allRestaurants[i]._id,
        "slug": allRestaurants[i].slug,
        "name": allRestaurants[i].name,
        "neighborhood": allRestaurants[i].location.city.neighborhood,
        "hhTime": allRestaurants[i].happyHour[today].options[0].time,
        "hhDrinks": allRestaurants[i].happyHour[today].options[0].drinks,
        "hhFood": allRestaurants[i].happyHour[today].options[0].food,
        "specialType": allRestaurants[i].specials[today].options[0].category,
        "specialOptions": allRestaurants[i].specials[today].options[0].items,
        "specialName": allRestaurants[i].specials[today].options[0].name,

      }

      todaysInfo.push(storeInfo)

    }

    todaysInfo.sort(function (a, b) {
      if (b.name.toLowerCase() < a.name.toLowerCase()) {
        return 1
      } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1
      } else {
        return 0
      }
    })
    // console.log(todaysInfo)
    return todaysInfo;
  }

  const sortByName = (input) => {
    // verifying input has value
    if (input) {
      let newInput = [...input];


      // if props.nameSortValue is true, make it a-z
      if (props.nameSortValue) {
        console.log('a-z')
        return newInput.sort(function (a, b) {
          if (b.name.toLowerCase() < a.name.toLowerCase()) {
            return 1
          } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1
          } else {
            return 0
          }
        })
      } else {
        console.log('z-a')
        return newInput.sort(function (a, b) {
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return 1
          } else if (b.name.toLowerCase() < a.name.toLowerCase()) {
            return -1
          } else {
            return 0
          }
        })
      }
    }
  }

  const filterByNeighborhood = (input) => {
    let newInput = [...input]

    if (props.specialsValue) {
      newInput = filterBySpecial(newInput)
    }

    if (props.neighborhoodValue) {

      if (props.neighborhoodValue.length === 1) {
        let filterByHood = newInput.filter(restaurant => restaurant.neighborhood === props.neighborhoodValue.toString())

        filterByHood = sortByName(filterByHood, props.nameSortValue)

        return filterByHood

        // } else if (props.neighborhoodValue === "all") {
        //   return newInput

      } else if (props.neighborhoodValue.length >= 2) {

        // going to push restaurants into this array if they match our conditions
        let filterByHood = []

        // if the neighborhoods that are selected match, put them in the array that we're going to set to state
        for (let i = 0; i < props.neighborhoodValue.length; i++) {
          for (let j = 0; j < newInput.length; j++) {
            if (props.neighborhoodValue[i] === newInput[j].neighborhood) {
              filterByHood.push(newInput[j])
            }
          }
        }

        // sort by name
        filterByHood = sortByName(filterByHood, props.nameSortValue)

        // set state w our filtered list
        return filterByHood

      } else {
        return newInput
      }

    } else {
      return newInput
    }
  }

  const filterBySpecial = (input) => {
    let newInput = [...input]

    if (props.specialsValue) {
      if (props.specialsValue.length === 1) {
        let filterBySpecial = newInput.filter(restaurant => restaurant.specialType.includes(props.specialsValue.toString()))

        filterBySpecial = sortByName(filterBySpecial)

        return filterBySpecial
      } else if (props.specialsValue.length >= 2) {
        let filterBySpecial = []

        // if the neighborhoods that are selected match, put them in the array that we're going to set to state
        for (let i = 0; i < props.specialsValue.length; i++) {
          for (let j = 0; j < newInput.length; j++) {
            if (newInput[j].specialType.includes(props.specialsValue[i])) {
              filterBySpecial.push(newInput[j])
            }
          }
        }


        // sort by name
        filterBySpecial = sortByName(filterBySpecial, props.nameSortValue)

        // set state w our filtered list
        return filterBySpecial
      } else {
        return newInput
      }
    } else {
      return newInput
    }
  }

  const getSpecialsArray = (input) => {
    let newInput = [...input]
    let specialsArray = []

    // create the array
    for (let i = 0; i < newInput.length; i++) {
      if (input[i].specialType.length < 0) {
        return
      } else if (input[i].specialType.length === 1) {
        specialsArray.push(input[i].specialType.toString())
      } else {
        let newArray = specialsArray.concat(input[i].specialType)
        specialsArray = newArray
      }
    }

    // filter out duplicates
    specialsArray = specialsArray.reduce((unique, item) => unique.includes(item) ? unique : [...unique, item], [])

    // put them in alpha order
    specialsArray = specialsArray.sort(function (a, b) {
      if (b.toLowerCase() < a.toLowerCase()) {
        return 1
      } else if (a.toLowerCase() < b.toLowerCase()) {
        return -1
      } else {
        return 0
      }
    })

    console.log("specialsArray")
    console.log(specialsArray)
    setTodaysSpecials(specialsArray)
    return specialsArray

  }

  return (
    <div className="home-filterable-data__container">
      <div className="home-filterable-data__filters-container">
        {allRestaurants &&
          <LeftColumnFilters
            neighborhoodValue={props.neighborhoodValue}
            hoodFilter={props.hoodFilter}
            specialsValue={props.specialsValue}
            specialsFilter={props.specialsFilter}
            specialOptionsValue={todaysSpecials}
            todayValue={props.todayValue}
            allData={allRestaurants}
          />

        }
      </div>
      <div className="home-filterable-data__results-container">
        {todaysRestaurants &&

          (todaysRestaurants.length >= 1) ? (
            todaysRestaurants.map(store => (
              <RestaurantCard
                key={store.id}
                id={store.id}
                slug={store.slug}
                name={store.name}
                neighborhood={store.neighborhood}
                hhHours={store.hhTime}
                hhDrinks={store.hhDrinks}
                hhFood={store.hhFood}
                specialType={store.specialType}
                specialOptions={store.specialOptions}
                specialName={store.specialName}
                todayValue={props.todayValue}

              />
            ))
          ) : (
            <div>Sorry homie: no results match your filters. Try checking another day, or choose more filter options.</div>
          )
        }


      </div>
    </div>
  )
}

export default FilterableData