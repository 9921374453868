import React from 'react'

import Slider from "react-slick";

export default function SpecialsSlider(props) {

  const sliderSettings = {
    // dots: true,
    infinite: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1
  }

  const updateSpecialArray = (input) => {
    let specialsArray = creatSpecialsArray(input)
    specialsArray = [...specialsArray]
    props.specialsFilter(specialsArray)
  }

  const creatSpecialsArray = (input) => {

    if (props.specialsValue === undefined) {
      let specialsArray = []
      specialsArray.push(input)
      return specialsArray
    } else {
      let specialsArray = props.specialsValue
      let newValue = input

      if (specialsArray.includes(newValue) === false) {
        specialsArray.push(newValue)
        return specialsArray

      } else {
        specialsArray = specialsArray.filter(list => list !== newValue)
        return specialsArray
      }

    }

  }

  return (
    <div className="landing-decision-tree__slider-wrapper">


      <Slider {...sliderSettings}>
        <div className="slide-1">
          <div className="specials-selector__options-container">

            <div className="home-hood-option">
              <input type="checkbox" id="Burgers" name="Burgers" value="Burgers" onClick={() => updateSpecialArray("burgers")} /><label htmlFor="Burgers">Burgers</label>
            </div>

            <div className="home-hood-option">
              <input type="checkbox" id="Tacos" name="Tacos" value="Tacos" onClick={() => updateSpecialArray("tacos")} /><label htmlFor="Tacos">Tacos</label>
            </div>

            <div className="home-hood-option">
              <input type="checkbox" id="Drinks" name="Drinks" value="Drinks" onClick={() => updateSpecialArray("booze")} /><label htmlFor="Drinks">Drinks</label>
            </div>
            <div className="home-hood-option">


              <input type="checkbox" id="Wings" name="Wings" value="Wings" onClick={() => updateSpecialArray("wings")} /><label htmlFor="Wings">Wings</label>
            </div>
          </div>
        </div>





        <div className="slide-2">
          <div className="specials-selector__options-container">
            <div className="home-hood-option">
              <input type="checkbox" id="Burgers" name="Burgers" value="Burgers" onClick={() => updateSpecialArray("wildcard")} /><label htmlFor="Burgers">Wildcard</label>
            </div>

            <div className="home-hood-option">
              <input type="checkbox" id="Tacos" name="Tacos" value="Tacos" onClick={() => updateSpecialArray("steak")} /><label htmlFor="steak">Steak</label>
            </div>

            <div className="home-hood-option">
              <input type="checkbox" id="Drinks" name="Drinks" value="Drinks" onClick={() => updateSpecialArray("trivia")} /><label htmlFor="Drinks">Trivia</label>
            </div>
            <div className="home-hood-option">


              <input type="checkbox" id="Wings" name="Wings" value="Wings" onClick={() => updateSpecialArray("seafood")} /><label htmlFor="Wings">Seafood</label>
            </div>
          </div>
        </div>
      </Slider>
    </div>
  )
}
