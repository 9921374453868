import React from 'react'
import { Link } from "react-scroll"


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'

export default function NeighborhoodOptions(props) {

  const updateHoodArray = (input) => {
    let hoodArray = createHoodArray(input)
    hoodArray = [...hoodArray]
    props.hoodFilter(hoodArray)
  }

  const createHoodArray = (input) => {

    if (props.hoodValue === undefined) {
      let hoodArray = []
      hoodArray.push(input)
      return hoodArray
    } else {
      let hoodArray = props.hoodValue
      let newValue = input

      if (hoodArray.includes(newValue) === false) {
        hoodArray.push(newValue)
        return hoodArray

      } else {
        hoodArray = hoodArray.filter(list => list !== newValue)
        return hoodArray
      }

    }

  }



  return (
    <div className={props.displayValue}>



      <div className="landing-decision-tree__header-wrapper">
        <FontAwesomeIcon
          icon={faArrowCircleLeft}
          onClick={() => props.clickFunc("back-from-hood")}
          className="hood-back-button"
        />
        <div className="landing-decision-tree__header">Select Your Neighborhoods</div>
      </div>

      <div className="landing-decision-tree__slider-wrapper">


        <div className="neighborhood-selector__options-container">







          <div className="home-hood-option">
          {props.hoodValue &&
            (props.hoodValue.includes("Canton")) ? (
              <input type="checkbox" onChange={() => updateHoodArray("Canton")} checked />
            ) : (
              <input type="checkbox" onChange={() => updateHoodArray("Canton")} />
            )}
            <label htmlFor="canton">Canton</label>
          </div>

          <div className="home-hood-option">
          {props.hoodValue &&
            (props.hoodValue.includes("Fell's Point")) ? (
              <input type="checkbox" onChange={() => updateHoodArray("Fell's Point")} checked />
            ) : (
              <input type="checkbox" onChange={() => updateHoodArray("Fell's Point")} />
            )}
            
            
            <label htmlFor="fells">Fell's Point</label>
          </div>

          <div className="home-hood-option">
          {props.hoodValue &&
            (props.hoodValue.includes("Federal Hill")) ? (
              <input type="checkbox" onChange={() => updateHoodArray("Federal Hill")} checked />
            ) : (
              <input type="checkbox" onChange={() => updateHoodArray("Federal Hill")} />
            )}
            <label htmlFor="fed">Federal Hill</label>
          </div>
          <div className="home-hood-option">


          {props.hoodValue &&
            (props.hoodValue.includes("Hampden")) ? (
              <input type="checkbox" onChange={() => updateHoodArray("Hampden")} checked />
            ) : (
              <input type="checkbox" onChange={() => updateHoodArray("Hampden")} />
            )}
            <label htmlFor="hampden">Hampden</label>
          </div>




        </div>
      </div>
      <button className="btn btn-home-selector-tray">

      <Link to="all" spy={true} smooth={true} offset={-10} duration={300} onClick={() => props.clickFunc("back-from-hood")} >
          
          Submit
          
      </Link>
      </button>

    </div >
  )
}
