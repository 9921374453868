import React, { useState, useEffect } from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHamburger, faDrumstickBite, faFish, faPizzaSlice, faBreadSlice, faBeer, faExclamationTriangle, faSkullCrossbones, faWineGlass, faGlassWhiskey, faBrain } from '@fortawesome/free-solid-svg-icons'

import beer from "../../../assets/icons/beer.svg"
import brunch from "../../../assets/icons/brunch.svg"
import cheesesteak from "../../../assets/icons/cheesesteak.svg"
import chef from "../../../assets/icons/chefs-special.svg"
import crab from "../../../assets/icons/crab.svg"
import hotdog from "../../../assets/icons/hotdog.svg"
import industry from "../../../assets/icons/industry.svg"
import liquor from "../../../assets/icons/liquor.svg"
import lobster from "../../../assets/icons/lobster.svg"
import martini from "../../../assets/icons/martini.svg"
import ribs from "../../../assets/icons/ribs.svg"
import salad from "../../../assets/icons/salad.svg"
import shrimp from "../../../assets/icons/shrimp.svg"
import steak from "../../../assets/icons/steak.svg"
import taco from "../../../assets/icons/taco.svg"

export default function CardIcons(props) {

  const [specialCategory, setSpecialCategory] = useState(undefined)

  useEffect(() => {
    let specials = props.specialType
    setSpecialCategory(specials)
  }, [props.specialType])

  return (
    <div className="home-card__icons-row">
      {(specialCategory !== undefined) &&
        (specialCategory.length >= 1) ? (
          ((specialCategory.includes("burgers")) && <FontAwesomeIcon icon={faHamburger} className="home-card__icon" title="Burger Night" />)
        ) :
        (<FontAwesomeIcon icon={faSkullCrossbones} className="home-card__icon" title="No Special" />)
      }


      {(specialCategory !== undefined) &&
        (specialCategory.length >= 1) && (
          ((specialCategory.includes("wings")) && <FontAwesomeIcon icon={faDrumstickBite} className="home-card__icon" title="Wing Night" />)
        )
      }


      {(specialCategory !== undefined) &&
        (specialCategory.length >= 1) && (
          ((specialCategory.includes("seafood")) && <FontAwesomeIcon icon={faFish} className="home-card__icon" title="Seafood/Crab Night" />)
        )
      }


      {(specialCategory !== undefined) &&
        (specialCategory.length >= 1) && (
          ((specialCategory.includes("pizza")) && <FontAwesomeIcon icon={faPizzaSlice} className="home-card__icon" title="Pizza Night" />)
        )
      }


      {(specialCategory !== undefined) &&
        (specialCategory.length >= 1) && (
          ((specialCategory.includes("sandwich")) && <FontAwesomeIcon icon={faBreadSlice} className="home-card__icon" title="Sandwich Night" />)
        )
      }


      {(specialCategory !== undefined) &&
        (specialCategory.length >= 1) && (
          ((specialCategory.includes("booze")) && <FontAwesomeIcon icon={faBeer} className="home-card__icon" title="Drink Specials" />)
        )
      }


      {(specialCategory !== undefined) &&
        (specialCategory.length >= 1) && (
          ((specialCategory.includes("wildcard")) && <FontAwesomeIcon icon={faExclamationTriangle} className="home-card__icon" title="Wildcard Special" />)
        )
      }


      {(specialCategory !== undefined) &&
        (specialCategory.length >= 1) && (
          ((specialCategory.includes("trivia")) && <FontAwesomeIcon icon={faBrain} className="home-card__icon" title="Trivia Night" />)
        )
      }


      {(specialCategory !== undefined) &&
        (specialCategory.length >= 1) && (
          ((specialCategory.includes("wine")) && <FontAwesomeIcon icon={faWineGlass} className="home-card__icon" title="Wine Night" />)
        )
      }


      {(specialCategory !== undefined) &&
        (specialCategory.length >= 1) && (
          ((specialCategory.includes("whiskey")) && <FontAwesomeIcon icon={faGlassWhiskey} className="home-card__icon" title="Whiskey Night" />)
        )
      }


      {(specialCategory !== undefined) &&
        (specialCategory.length >= 1) && (
          ((specialCategory.includes("beer")) && <img src={beer} className="home-card__icon home-card__icon--square" title="Beer Night" alt="beer" />)
        )
      }


      {(specialCategory !== undefined) &&
        (specialCategory.length >= 1) && (
          ((specialCategory.includes("brunch")) && <img src={brunch} className="home-card__icon home-card__icon--square" title="Brunch!" alt="brunch" />)
        )
      }

      {(specialCategory !== undefined) &&
        (specialCategory.length >= 1) && (
          ((specialCategory.includes("cheesesteaks")) && <img src={cheesesteak} className="home-card__icon-short" title="Cheesesteak Night" alt="cheesesteak" />)
        )
      }


      {(specialCategory !== undefined) &&
        (specialCategory.length >= 1) && (
          ((specialCategory.includes("chef")) && <img src={chef} className="home-card__icon home-card__icon--square" title="Chef's Special Night" alt="chef" />)
        )
      }
      {(specialCategory !== undefined) &&
        (specialCategory.length >= 1) && (
          ((specialCategory.includes("crab")) && <img src={crab} className="home-card__icon home-card__icon--square" title="Crab Night" alt="crab" />)
        )
      }


      {(specialCategory !== undefined) &&
        (specialCategory.length >= 1) && (
          ((specialCategory.includes("hotdogs")) && <img src={hotdog} className="home-card__icon-short" title="Hot Dog Night" alt="hot dog" />)
        )
      }


      {(specialCategory !== undefined) &&
        (specialCategory.length >= 1) && (
          ((specialCategory.includes("industry")) && <img src={industry} className="home-card__icon home-card__icon--square" title="Industry Night" alt="waiter" />)
        )
      }


      {(specialCategory !== undefined) &&
        (specialCategory.length >= 1) && (
          ((specialCategory.includes("liquor")) && <img src={liquor} className="home-card__icon home-card__icon--square" title="Liquor Night" alt="liquor bottle" />)
        )
      }


      {(specialCategory !== undefined) &&
        (specialCategory.length >= 1) && (
          ((specialCategory.includes("ribs")) && <img src={ribs} className="home-card__icon home-card__icon--square" title="Ribs Night" alt="ribs" />)
        )
      }


      {(specialCategory !== undefined) &&
        (specialCategory.length >= 1) && (
          ((specialCategory.includes("martini")) && <img src={martini} className="home-card__icon-short" title="Martini Night" alt="martini night" />)
        )
      }


      {(specialCategory !== undefined) &&
        (specialCategory.length >= 1) && (
          ((specialCategory.includes("salad")) && <img src={salad} className="home-card__icon home-card__icon--square" title="Salad Night" alt="salad" />)
        )
      }
      {(specialCategory !== undefined) &&
        (specialCategory.length >= 1) && (
          ((specialCategory.includes("shrimp")) && <img src={shrimp} className="home-card__icon home-card__icon--square" title="Shrimp Night" alt="shrimp" />)
        )
      }
      {(specialCategory !== undefined) &&
        (specialCategory.length >= 1) && (
          ((specialCategory.includes("steak")) && <img src={steak} className="home-card__icon home-card__icon--square" title="Steak Night" alt="steak" />)
        )
      }
      {(specialCategory !== undefined) &&
        (specialCategory.length >= 1) && (
          ((specialCategory.includes("lobster")) && <img src={lobster} className="home-card__icon home-card__icon--square" title="Lobster Night" alt="lobster" />)
        )
      }
      {(specialCategory !== undefined) &&
        (specialCategory.length >= 1) && (
          ((specialCategory.includes("tacos")) && <img src={taco} className="home-card__icon-short" title="Taco Night" alt="taco" />)
        )
      }


    </div>
  )
}
