import React from 'react'

export default function About(props) {
  return (
    <div className="special-features__container">
      <div className="special-features__content-container">
        <h2>About {props.name}</h2>
        <ul>

          {props.aboutInfo &&
            props.aboutInfo.map(descript => (
              <li key={descript}>{descript}</li>
            ))
          }

        </ul>
      </div>
    </div>
  )
}
