import React, { useState, useEffect } from "react";

import Hero from '../components/home/Hero'
import DaySelectorBar from "../components/home/DaySelectorBar"
import FilterableData from "../components/home/FilterableData"
import Footer from "../components/global/Footer";

export default function Home() {

  const [today, setToday] = useState(undefined)
  const [neighborhood, setNeighborhood] = useState(undefined)
  const [specials, setSpecials] = useState(undefined)
  const [specialOptions, setSpecialOptions] = useState(undefined)


  useEffect(() => {
    const findDate = new Date().getDay()
    setToday(findDate)

    setTimeout(() => {
      window.scrollTo(0, 0)
    }, 400)

    document.body.className = ""
  }, [])

  const neighborHoodFilter = input => {
    setNeighborhood(input)
  }

  const specialsFilter = input => {
    setSpecials(input)
  }

  const dayChange = input => {
    setToday(input)
  }

  const specialOptionsChange = input => {
    setSpecialOptions(input)
  }

  return (
    <div className="page">
      {(today || today === 0) &&
        <div>
          <Hero
            hoodValue={neighborhood}
            hoodFilter={neighborHoodFilter}
            specialsValue={specials}
            specialsFilter={specialsFilter}
          />
          <DaySelectorBar
            dayValue={today}
            dayChange={dayChange}
          />
          <FilterableData
            todayValue={today}
            neighborhoodValue={neighborhood}
            hoodFilter={neighborHoodFilter}
            specialsValue={specials}
            specialsFilter={specialsFilter}
            specialOptionsValue={specialOptions}
            specialOptions={specialOptionsChange}

          />
        </div>
      }
      <Footer className="footer__home-page" />
    </div >
  )
}
