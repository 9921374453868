import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet"

import HoodMainInfo from "../components/restaurant-pages/HoodMainInfo";
import About from "../components/restaurant-pages/About";
import TodaysInfo from "../components/restaurant-pages/TodaysInfo";
import Footer from "../components/global/Footer";


export default function RestaurantPage() {
  const [today, setToday] = useState(undefined)
  const [about, setAbout] = useState(undefined)
  const [name, setName] = useState(undefined)
  const [neighborhood, setNeighborhood] = useState(undefined)
  const [phone, setPhone] = useState(undefined)
  const [htmlTitle, setHTMLTitle] = useState(undefined)

  useEffect(() => {
    const findDate = new Date().getDay()
    setToday(findDate)

    setTimeout(() => {
      window.scrollTo(0, 0)

    }, 200)

  }, [])

  // useEffect(() => {
  //   if (neighborhood !== undefined) {
  //     setBackground(neighborhood)
  //   }
  // }, [neighborhood])

  const setBackground = (input) => {
    if (input === "Canton") {
      setTimeout(() => {

        document.body.classList.add("fade-in-400")
        document.body.classList.add("neighborhood-hero")
        document.body.classList.add("hood-hero-canton")
      }, 200)

    } else {
      setTimeout(() => {

        document.body.classList.add("fade-in-400")
        document.body.classList.add("neighborhood-hero")
        document.body.classList.add("hood-hero-canton")
      }, 200)
    }
  }

  const quickInfo = (nameInput, hoodInput, phoneInput) => {
    setName(nameInput)
    setHTMLTitle(nameInput + " Happy Hours and Specials | " + hoodInput + " | Baltimore Happy Hours")
    setNeighborhood(hoodInput)
    setPhone(phoneInput)
  }

  const dayChange = (input) => {
    setToday(input)
  }

  const aboutInfo = (input) => {
    setAbout(input)
  }

  return (
    <div>
      <div className="neighborhood-hero hood-hero-canton"></div>
      {(today || today === 0) &&
        <div className="hood-content-container">
          <Helmet>
            <title>{htmlTitle}</title>
          </Helmet>

          {/* <div className="footer__content-aware"> */}
          <HoodMainInfo
            name={name}
            hood={neighborhood}
            phone={phone}
          />
          <div className="hood-quick-info-container">
            <TodaysInfo
              quickInfo={quickInfo}
              today={today}
              dayChange={dayChange}
              aboutInfo={aboutInfo}

            />
            <About
              name={name}
              aboutInfo={about}
            />

          </div>


          {/* </div> */}
          <Footer className={"footer__restaurant-page"} />
          {/* <Footer className={"footer__home-page"} /> */}

        </div>




      }
    </div>
  )
}
