import React from 'react'

export default function DaySelector(props) {
  const dayChange = (input) => {
    props.dayChange(input)
  }

  const dayButtonObject = [
    {
      name: "M",
      fullDay: "Monday",
      index: 1
    },
    {
      name: "Tu",
      fullDay: "Tuesday",
      index: 2
    },
    {
      name: "W",
      fullDay: "Wednesday",
      index: 3
    },
    {
      name: "Th",
      fullDay: "Thursday",
      index: 4
    },
    {
      name: "F",
      fullDay: "Friday",
      index: 5
    },
    {
      name: "Sa",
      fullDay: "Saturday",
      index: 6
    },
    {
      name: "Su",
      fullDay: "Sunday",
      index: 0
    },
  ]

  return (
    <div className="hh-specials__day-selector">
      {
        dayButtonObject.map(dayButton => (
          (props.dayValue === dayButton.index) ? (
            <div className="btn-hh-day-selector btn-hh-day-selector--active d-flex-center-center" onClick={() => dayChange(dayButton.index)} key={dayButton.name}>{dayButton.name}</div>
          ) : (
              <div className="btn-hh-day-selector d-flex-center-center" onClick={() => dayChange(dayButton.index)} key={dayButton.name}>{dayButton.name}</div>
            )))
      }
    </div>
  )
}
