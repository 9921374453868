import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import API from "../../utils/API";
import DaySelector from "./DaySelector";
import HHSpecialInfo from "./HHSpecialInfo";


export default function TodaysInfo(props) {
  const [allRestaurantInfo, setAllRestaurantInfo] = useState(undefined)
  const [todaysInfo, setTodaysInfo] = useState(undefined)

  let { slug } = useParams()


  useEffect(() => {
    getAPI()
  }, [])

  useEffect(() => {
    if (allRestaurantInfo !== undefined) {
      let restaurantName = allRestaurantInfo.name
      let restaurantHood = allRestaurantInfo.location.city.neighborhood
      let restaurantPhone = allRestaurantInfo.location.phone

      document.title = restaurantName + " Happy Hours and Specials | " + restaurantHood + " | Baltimore Happy Hours"

      props.quickInfo(restaurantName, restaurantHood, restaurantPhone)

      let todayInfoObject = filterByDay(props.today)
      setTodaysInfo(todayInfoObject)

      // send the about information to the parent component
      let aboutArray = updateFeatures(allRestaurantInfo)
      props.aboutInfo(aboutArray)

    }


  }, [allRestaurantInfo, props.today])

  const getAPI = () => {
    API.getRestaurant(slug)
      .then(res => { setAllRestaurantInfo(res.data) })
      .catch(err => console.log(err))
  }

  const filterByDay = (today) => {
    // console.log(allRestaurantInfo)
    let todaysObject = {
      "day": allRestaurantInfo.specials[today].day,
      "specialOptions": allRestaurantInfo.specials[today].options,
      "happyHourOptions": allRestaurantInfo.happyHour[today].options
    }

    return todaysObject
  }

  const updateFeatures = (input) => {
    let newFeaturesArray = []

    // add a the description
    let descriptionArray = addDescription(input)

    newFeaturesArray = newFeaturesArray.concat(descriptionArray)

    let gamesString = addGames(input)
    if (gamesString !== undefined) {
      newFeaturesArray.push(gamesString)
    }

    let musicString = addMusic(input)
    if (musicString !== undefined) {
      newFeaturesArray.push(musicString)
    }

    let outdoorSeatingString = addOutdoorSeating(input)
    if (outdoorSeatingString !== undefined) {
      newFeaturesArray.push(outdoorSeatingString)
    }

    let sportsString = addSportsTeam(input)
    if (sportsString !== undefined) {
      newFeaturesArray.push(sportsString)
    }

    let triviaString = addTriviaNight(input)
    if (triviaString !== undefined) {
      newFeaturesArray.push(triviaString)
    }

    if (newFeaturesArray.length === 0) {
      return
    }

    return newFeaturesArray
  }

  const addDescription = (input) => {
    let descriptionArray = input.features.description
    return descriptionArray
  }

  const addGames = (input) => {

    // if it's not a games game, we should exit this immediately
    if (input.features.games.gamesBar === false) {
      return
    } else {
      let gamesArray = input.features.games.type

      if (gamesArray.length === 1) {
        return "Games: " + gamesArray
      } else if (gamesArray.length > 1) {
        let gamesString = "Games: " + gamesArray.join(", ")

        return gamesString
      } else {
        return
      }
    }
  }

  const addMusic = (input) => {
    if (input.features.music.danceFloor === false && input.features.music.dj === false && input.features.music.jukebox === false && input.features.music.liveMusic === false) {
      return
    } else {
      let musicString = "Music: "
      let musicOptions = []
      if (input.features.music.liveMusic) {
        musicOptions.push("live music")
      }
      if (input.features.music.dj) {
        musicOptions.push("DJ")
      }
      if (input.features.music.jukebox) {
        musicOptions.push("jukebox")
      }
      if (input.features.music.danceFloor) {
        musicOptions.push("dance floor")
      }

      // put it all together, what do you got?
      musicString = musicString + musicOptions.join(", ")
      return musicString
    }
  }

  const addOutdoorSeating = (input) => {
    if (input.features.outdoorSeating === false) {
      return
    } else {
      let outdoorSeatingString = "Outdoor seating available"
      return outdoorSeatingString
    }
  }

  const addSportsTeam = (input) => {
    if (input.features.sports.sportsBar === false) {
      return
    } else {
      let sportsArray = input.features.sports.teams
      if (sportsArray.length === 1) {
        return "Sports teams: " + sportsArray
      } else if (sportsArray.length > 1) {
        let sportsString = "Sports teams: " + sportsArray.join(", ")
        return sportsString
      } else {
        return
      }
    }
  }

  const addTriviaNight = (input) => {
    if (input.features.trivia.triviaBar === false) {
      return
    } else {
      return "Trivia Night: " + input.features.trivia.night
    }
  }

  return (
    <div className="hh-specials__container">
      {
        todaysInfo &&
        <div className="hh-specials__content-container">

          <DaySelector
            dayChange={props.dayChange}
            dayValue={props.today}
          />
          <HHSpecialInfo
            todaysInfo={todaysInfo}
          />

        </div>

      }

    </div>
  )
}
