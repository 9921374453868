import React, { useState, useEffect } from "react";

export default function SpecialsLeftFilter(props) {
  const [filteredSpecials, setFilteredSpecials] = useState(undefined)



  useEffect(() => {
    getAllTodaysSpecials(props.allData)
  }, [])

  useEffect(() => {
    getAllTodaysSpecials(props.allData)
  }, [props.todayValue])




  const getAllTodaysSpecials = (input) => {
    let allData = [...input]

    let todaysInfo = []

    for (let i = 0; i < allData.length; i++) {
      let storeInfo = {
        "id": allData[i]._id,
        "name": allData[i].name,
        "neighborhood": allData[i].location.city.neighborhood,
        "specialType": allData[i].specials[props.todayValue].options[0].category,

      }

      todaysInfo.push(storeInfo)

    }


    todaysInfo.sort(function (a, b) {
      if (b.name.toLowerCase() < a.name.toLowerCase()) {
        return 1
      } else if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1
      } else {
        return 0
      }
    })

    console.log("NEW FEATURE ***===---===***")
    console.log(todaysInfo)

    todaysInfo = getSpecialsArray(todaysInfo)


    setFilteredSpecials(todaysInfo);

  }


  const getSpecialsArray = (input) => {
    let newInput = [...input]
    let specialsArray = []

    // create the array
    for (let i = 0; i < newInput.length; i++) {
      if (input[i].specialType.length < 0) {
        return
      } else if (input[i].specialType.length === 1) {
        specialsArray.push(input[i].specialType.toString())
      } else {
        let newArray = specialsArray.concat(input[i].specialType)
        specialsArray = newArray
      }
    }

    // filter out duplicates
    specialsArray = specialsArray.reduce((unique, item) => unique.includes(item) ? unique : [...unique, item], [])

    // put them in alpha order
    specialsArray = specialsArray.sort(function (a, b) {
      if (b.toLowerCase() < a.toLowerCase()) {
        return 1
      } else if (a.toLowerCase() < b.toLowerCase()) {
        return -1
      } else {
        return 0
      }
    })

    console.log("specialsArray")
    console.log(specialsArray)
    // setTodaysSpecials(specialsArray)
    return specialsArray

  }


  const updateSpecialArray = (input) => {
    let specialsArray = createSpecialsArray(input)
    specialsArray = [...specialsArray]
    props.specialsFilter(specialsArray)
  }

  const createSpecialsArray = (input) => {

    if (props.specialsValue === undefined) {
      let specialsArray = []
      specialsArray.push(input)
      return specialsArray
    } else {
      let specialsArray = props.specialsValue
      let newValue = input

      if (specialsArray.includes(newValue) === false) {
        specialsArray.push(newValue)
        return specialsArray

      } else {
        specialsArray = specialsArray.filter(list => list !== newValue)
        return specialsArray
      }
    }
  }


  return (
    <div style={{ marginTop: 30 }}>
      <div className="left-column__filter-header">Specials</div>
      <div className="left-column__option-contatiner">











        {filteredSpecials &&
          filteredSpecials.map(option => (
            <div key={option} className="left-column__option">
              {props.specialsValue &&
                (props.specialsValue.includes(option)) ? (
                  <input type="checkbox" onChange={() => updateSpecialArray(option)} checked />
                ) : (
                  <input type="checkbox" onChange={() => updateSpecialArray(option)} />
                )}
              <label htmlFor="burgers">{option}</label>
            </div>
          ))
        }







        {/* {props.specialOptionsValue &&
          props.specialOptionsValue.map(option => (
            <div key={option} className="left-column__option">
              {props.specialsValue &&
                (props.specialsValue.includes(option)) ? (
                  <input type="checkbox" onChange={() => updateSpecialArray(option)} checked />
                ) : (
                  <input type="checkbox" onChange={() => updateSpecialArray(option)} />
                )}
              <label htmlFor="burgers">{option}</label>
            </div>
          ))
        } */}



        {/* 
        <div className="left-column__option">
          {props.specialsValue &&
            (props.specialsValue.includes("burgers")) ? (
              <input type="checkbox" onChange={() => updateSpecialArray("burgers")} checked />
            ) : (
              <input type="checkbox" onChange={() => updateSpecialArray("burgers")} />
            )}
          <label htmlFor="burgers">Burgers</label>
        </div>
        <div className="left-column__option">
          {props.specialsValue &&
            (props.specialsValue.includes("tacos")) ? (
              <input type="checkbox" onChange={() => updateSpecialArray("tacos")} checked />
            ) : (
              <input type="checkbox" onChange={() => updateSpecialArray("tacos")} />
            )}
          <label htmlFor="tacos">Tacos</label>
        </div>
        <div className="left-column__option">
          {props.specialsValue &&
            (props.specialsValue.includes("booze")) ? (
              <input type="checkbox" onChange={() => updateSpecialArray("booze")} checked />
            ) : (
              <input type="checkbox" onChange={() => updateSpecialArray("booze")} />
            )}
          <label htmlFor="drinks">Drinks</label>
        </div>
        <div className="left-column__option">
          {props.specialsValue &&
            (props.specialsValue.includes("wings")) ? (
              <input type="checkbox" onChange={() => updateSpecialArray("wings")} checked />
            ) : (
              <input type="checkbox" onChange={() => updateSpecialArray("wings")} />
            )}
          <label htmlFor="wings">Wings</label>
        </div>

         */}
      </div>
    </div>
  )
}
