import React, { useState } from "react";

// ************ COMPONENTS
import InitialOptions from './hero-selector-options/InitialOptions'
import NeighborhoodOptions from './hero-selector-options/NeighborhoodOptions'
import SpecialOptions from './hero-selector-options/SpecialOptions'


export default function Hero(props) {
  const [initialOptionsValue, setInitialOptionsValue] = useState(true)
  const [hoodDisplayValue, setHoodDisplayValue] = useState(undefined)
  const [specialsDisplayValue, setSpecialsDisplayValue] = useState(undefined)


  const landingDecisionTreeClick = (value) => {

    if (value === "neighborhood") {
      // make initial options dissapear
      setInitialOptionsValue(false);

      // delay on setting hood display to true so the initial options can fade out before hood options can fade in
      setTimeout(function () {
        setHoodDisplayValue(true)
      }, 300)
    } else if (value === "specials") {
      setInitialOptionsValue(false)

      setTimeout(function () {
        setSpecialsDisplayValue(true)
      }, 300)
    } else if (value === "back-from-hood") {
      setHoodDisplayValue(false)

      setTimeout(function () {
        setInitialOptionsValue(true)
      }, 300)

    } else {
      // make hood options dissapear
      setSpecialsDisplayValue(false)

      // delay on setting initial display to true so the hood options can fade out before initial options can fade in
      setTimeout(function () {
        setInitialOptionsValue(true)
      }, 300)
    }
  }

  return (
    <div className="landing-hero">

      {/* change this so it's just the black box that never leaves */}
      <div className="landing-decision-tree__gray-box">

        <InitialOptions
          clickFunc={landingDecisionTreeClick}
          displayValue={initialOptionsValue ? "landing-decision-tree__container fade-in" : "landing-decision-tree__container fade-out"}
        />

        <NeighborhoodOptions
          clickFunc={landingDecisionTreeClick}
          hoodFilter={props.hoodFilter}
          hoodValue={props.hoodValue}
          displayValue={(hoodDisplayValue === undefined) ? ("landing-decision-tree__container no-display") :
            hoodDisplayValue ? "landing-decision-tree__container fade-in" : "landing-decision-tree__container fade-out"}
        />

        <SpecialOptions
          clickFunc={landingDecisionTreeClick}
          specialsFilter={props.specialsFilter}
          specialsValue={props.specialsValue}
          displayValue={(specialsDisplayValue === undefined) ? ("landing-decision-tree__container no-display") :
            specialsDisplayValue ? "landing-decision-tree__container fade-in" : "landing-decision-tree__container fade-out"}
        />


        {/* another component here for the neighborhood selector. this shows if var === "neighborhood" */}


        {/* third component here for the specials selector. it's going to be the same exact setup as the neighborhood selector, just we need to use specials as the options instead of hoods */}

        {/* there isn't a component for the 'see everyting' button ... if var - "see everything" then it's going to scroll down the page to the large view */}
      </div>
    </div>
  )
}
