import React from 'react';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CSSTransition, TransitionGroup } from 'react-transition-group'

// Pages
import Home from './pages/Home'
import NoMatch from './pages/NoMatch'

// Components
import Nav from './components/global/Nav'
import RestaurantPage from './pages/RestaurantPage';

function App() {
  return (
    <Router>
      <Nav />
      <Route render={({ location }) => (
        <TransitionGroup>
          <CSSTransition
            key={location.key}
            timeout={400}
            classNames="fade"
          >
            <Switch location={location}>
              <Route exact path="/">
                <Home />
              </Route>
              <Route exact path="/:slug">
                <RestaurantPage />
              </Route>
              <Route>
                <NoMatch />
              </Route>
            </Switch>

          </CSSTransition>
        </TransitionGroup>
      )}
      />

    </Router>
  );
}

export default App;
