import React from 'react'

export default function DaySelectorBar(props) {

  const dayChange = (input) => {
    props.dayChange(input)
  }

  const dayButtonObject = [
    {
      name: "M",
      fullDay: "Monday",
      index: 1
    },
    {
      name: "Tu",
      fullDay: "Tuesday",
      index: 2
    },
    {
      name: "W",
      fullDay: "Wednesday",
      index: 3
    },
    {
      name: "Th",
      fullDay: "Thursday",
      index: 4
    },
    {
      name: "F",
      fullDay: "Friday",
      index: 5
    },
    {
      name: "Sa",
      fullDay: "Saturday",
      index: 6
    },
    {
      name: "Su",
      fullDay: "Sunday",
      index: 0
    },
  ]

  const changeTodaySpecialHeader = (dayValue) => {
    const today = new Date().getDay();

    switch (dayValue) {
      case today:
        return "Today";
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      case 6:
        return "Saturday";
      default:
        return "Sunday";
    }

  }




  return (
    <div className="home-day-selector__container" id="all">
      {/* Header */}
      <div className="home-day-selector__header">{changeTodaySpecialHeader(props.dayValue)}'s Specials</div>

      {/* Buttons */}

      {
        dayButtonObject.map(dayButton => (

          (props.dayValue === dayButton.index) ? (
            <div className="btn-home-day-selector btn-home-day-selector--active d-flex-center-center" onClick={() => dayChange(dayButton.index)} key={dayButton.name}>{dayButton.name}</div>
          ) : (
              <div className="btn-home-day-selector d-flex-center-center" onClick={() => dayChange(dayButton.index)} key={dayButton.name}>{dayButton.name}</div>
            )




        ))
      }

    </div>
  )
}
