import React from 'react'

export default function HHSpecialInfo(props) {
  return (
    <div className="hh-specials__info-display">
      <h2>Specials</h2>
      <h3 className="hh-specials__specials-header">{props.todaysInfo.specialOptions[0].name}</h3>

      {props.todaysInfo.specialOptions[0].items.length === 0 ? (
        <div style={{ textAlign: "center", marginTop: 15, marginBottom: 30 }}>No Specials Today</div>
      ) : (

          <ul>
            {props.todaysInfo.specialOptions[0].items.map(detail => (
              <li key={detail}>{detail}</li>
            ))}
          </ul>
        )}



      <h2 className="hh-specials__hh-header">Happy Hour</h2>
      <div className="hh-specials__hh-time">{props.todaysInfo.happyHourOptions[0].time}</div>
      <div className="hh-specials__hh-items-container">
        {(props.todaysInfo.happyHourOptions[0].drinks.length === 0) ? (
          <div style={{ textAlign: "center", marginTop: 15 }}>No Happy Hour today</div>
        ) : (
            <ul>
              {props.todaysInfo.happyHourOptions[0].drinks.map(detail => (
                <li key={detail}>{detail}</li>
              ))}
              {props.todaysInfo.happyHourOptions[0].food.map(detail => (
                <li key={detail}>{detail}</li>
              ))}
            </ul>
          )

        }



      </div>

    </div>
  )
}
