import axios from "axios"

export default {
  getRestaurants: function () {
    return axios.get("/api/restaurants")
  },

  getRestaurant: function (slug) {
    return axios.get("/api/restaurants/" + slug)
  },

}