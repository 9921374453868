import React from 'react'
import { Link } from "react-scroll"



import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'
import SpecialsSlider from './SpecialsSlider';

export default function SpecialOptions(props) {


  return (
    <div className={props.displayValue}>
      <div className="landing-decision-tree__header-wrapper">
        <FontAwesomeIcon
          icon={faArrowCircleLeft}
          onClick={() => props.clickFunc("all")}
          className="hood-back-button"
        />
        <div className="landing-decision-tree__header">Select Your Specials</div>
      </div>
      <SpecialsSlider
        specialsFilter={props.specialsFilter}
        specialsValue={props.specialsValue}
      />
        <button className="btn btn-home-selector-tray">
          <Link to="all" spy={true} smooth={true} offset={-10} duration={300} onClick={() => props.clickFunc("all")} >
          
          Submit
          
          </Link>
          </button>

    </div>








  )
}
