import React from 'react'

export default function NeighborhoodsLeftFilter(props) {

  const updateHoodArray = (input) => {
    let hoodArray = createHoodArray(input)
    hoodArray = [...hoodArray]
    props.hoodFilter(hoodArray)
  }

  const createHoodArray = (input) => {

    if (props.neighborhoodValue === undefined) {
      let hoodArray = []
      hoodArray.push(input)
      return hoodArray
    } else {
      let hoodArray = props.neighborhoodValue
      let newValue = input

      if (hoodArray.includes(newValue) === false) {
        hoodArray.push(newValue)
        return hoodArray

      } else {
        hoodArray = hoodArray.filter(list => list !== newValue)
        return hoodArray
      }

    }

  }


  return (
    <div>
      <div className="left-column__filter-header">Neighborhoods</div>
      <div className="left-column__option-contatiner">
        <div className="left-column__option">

          {props.neighborhoodValue &&

            (props.neighborhoodValue.includes("Canton")) ? (
              <input type="checkbox" onChange={() => updateHoodArray("Canton")} checked />
            ) : (
              <input type="checkbox" onChange={() => updateHoodArray("Canton")} />
            )}
          <label htmlFor="canton">Canton</label>
        </div>


        <div className="left-column__option">
          {props.neighborhoodValue &&

            (props.neighborhoodValue.includes("Fell's Point")) ? (
              <input type="checkbox" onChange={() => updateHoodArray("Fell's Point")} checked />
            ) : (
              <input type="checkbox" onChange={() => updateHoodArray("Fell's Point")} />
            )}
          <label htmlFor="fells">Fell's Point</label>
        </div>


        <div className="left-column__option">
          {props.neighborhoodValue &&

            (props.neighborhoodValue.includes("Federal Hill")) ? (
              <input type="checkbox" onChange={() => updateHoodArray("Federal Hill")} checked />
            ) : (
              <input type="checkbox" onChange={() => updateHoodArray("Federal Hill")} />
            )}
          <label htmlFor="fed">Federal Hill</label>
        </div>


        <div className="left-column__option">
          {props.neighborhoodValue &&

            (props.neighborhoodValue.includes("Hampden")) ? (
              <input type="checkbox" onChange={() => updateHoodArray("Hampden")} checked />
            ) : (
              <input type="checkbox" onChange={() => updateHoodArray("Hampden")} />
            )}
          <label htmlFor="hampden">Hampden</label>
        </div>
      </div>
    </div>
  )
}
