import React from 'react'
import logo from '../../assets/logos/baltimore-happy-hour-logo-black.png'
import { Link } from "react-router-dom";

export default function Nav() {
  return (
    <div className="nav-container">
      <Link to="/">
        <img src={logo} className="nav-logo" alt="Baltimore Happy Hours logo" />
      </Link>
      <Link to="/">
        <div className="nav-brand-header">Baltimore Happy Hours</div>

      </Link>
    </div>
  )
}
