import React from 'react'
import NeighborhoodsLeftFilter from './NeighborhoodsLeftFilter'
import SpecialsLeftFilter from './SpecialsLeftFilter'
import OtherLeftFilter from './OtherLeftFilter'

export default function LeftColumnFilters(props) {
  return (
    <div>
      <NeighborhoodsLeftFilter
        neighborhoodValue={props.neighborhoodValue}
        hoodFilter={props.hoodFilter}
      />
      <SpecialsLeftFilter
        specialsValue={props.specialsValue}
        specialsFilter={props.specialsFilter}
        specialOptionsValue={props.specialOptionsValue}
        todayValue={props.todayValue}
        allData={props.allData}
      />
      <OtherLeftFilter />
    </div>
  )
}
