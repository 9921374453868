import React from 'react'

export default function HoodMainInfo(props) {
  return (
    <div className="hood-main-info__container">
      <div className="hood-main-info__header">
        <h1>{props.name}</h1>
      </div>
      <div className="hood-main-info__subheader">
        <div style={{ marginRight: 25 }}>{props.hood}</div>
        <div>{props.phone}</div>
      </div>
    </div>
  )
}
