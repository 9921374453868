import React from 'react'
import { Link } from "react-scroll"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faStar, faSearch } from '@fortawesome/free-solid-svg-icons'

export default function InitialOptions(props) {
  return (
    <div className={props.displayValue}>
      <div className="landing-decision-tree__grid">

        <div className="landing-decision-tree-header">Search By</div>
        <div className="landing-options-container">
          <div
            className="landing-option"
            onClick={() => props.clickFunc("neighborhood")}
            value="neighborhood"
          >
            <FontAwesomeIcon icon={faHome} /><br />
              Neighborhood
            </div>
          <div
            className="landing-option"
            onClick={() => props.clickFunc("specials")}
            value="specials"
          >
            <FontAwesomeIcon icon={faStar} /><br />
              Specials
            </div>
          <div className="landing-option">
            <Link to="all" spy={true} smooth={true} offset={-10} duration={300} >
              <FontAwesomeIcon icon={faSearch} /><br />
              See Everything

          </Link>
          </div>
        </div>
      </div>
    </div >
  )
}
